<template>
  <div class="component-card-field">
    <KCheckbox
      v-if="prop?.type.startsWith('boolean')"
      v-model="modelValue"
      :data-testid="`component-prop-${prop.name}`"
      :description="prop?.description"
      :label="prop.label"
      :name="`prop-${prop.name}`"
      :required="prop.required"
    />
    <KSelect
      v-else-if="prop.options?.length && prop.name !== 'label'"
      v-model="modelValue"
      :data-testid="`component-prop-${prop.name}`"
      :help="prop?.description"
      :items="prop.options"
      :label="prop.label"
      :name="`prop-${prop.name}`"
      :required="prop.required"
    />
    <KInput
      v-else
      v-model.trim="modelValue"
      autocapitalize="off"
      autocomplete="off"
      :data-testid="`component-prop-${prop.name}`"
      :help="prop?.description"
      :label="prop.label"
      :name="`prop-${prop.name}`"
      :required="prop?.required"
      :type="prop?.type.includes('number') ? 'number' : 'text'"
    />
  </div>
</template>

<script setup lang="ts">
defineProps({
  prop: {
    type: Object as PropType<ComponentCardPropField>,
    required: true,
  },
})

const [modelValue, modelModifiers] = defineModel<any>({
  // get() omitted as it is not needed here
  set(value) {
    // if the .trim modifier is used, return trimmed value
    if (typeof value === 'string' && modelModifiers.trim) {
      return value.trim()
    }
    return value
  },
})
</script>
